import React from 'react';
import MyGarage from '../components/my-garage';

export default function Home(props) {
  return (
    <div className="home-container">
      <MyGarage />
    </div>
  );
}
