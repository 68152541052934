import React from 'react';
import MyServices from '../components/my-service';

export default function MyServicesPage(props) {
  return (
    <div className="home-container">
      <MyServices />
    </div>
  );
}
